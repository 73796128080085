<template>
  <!-- GPS -->
  <div class="role_index" id="roleList">
    <div class="role_content">
      <div class="role_button">
        
        <el-input
          placeholder="所属企业"
          style="width: 250px"
          v-model="searchForm.subjectionEnterprise"
          clearable
        ></el-input>

        <el-input
          placeholder="设备编码"
          class="ml20"
          style="width: 250px"
          v-model="searchForm.gpsDeviceCode"
          suffix-icon="el-icon-search"
        ></el-input>

        <el-button type="primary" class="ml30" @click="search" style="margin-top: 20px"
          >查询</el-button
        >
      </div>
      <div style="height: 20px; background: #f6f7f9"></div>
      <div style="padding: 0 20px">
        <el-button type="primary" @click="addRole" style="margin: 20px 0"
          >新增</el-button
        >
        <!-- <h4 style="padding-top: 20px; margin-bottom: 20px">角色列表</h4> -->
        <el-table border :data="tableData">
            <!-- <el-table-column
                type="selection"
                width="55">
            </el-table-column> -->
          <el-table-column prop="subjectionEnterprise" width="200" label="所属企业"></el-table-column>
          <el-table-column prop="agriculturalCode" width="160" label="农机编号"></el-table-column>
          <el-table-column prop="gpsDeviceCode" width="140" label="GPS设备编码"></el-table-column>
          <el-table-column prop="deviceStatus" width="80" label="状态">
            <template slot-scope="scope">
              <span>{{scope.row.deviceStatus == 1 ? '在线' : '离线'}}</span>
            </template>
          </el-table-column>
          <!--  设备状态 0：离线 1：在线   (如果不传，默认为 0离线) -->
          <el-table-column prop="machineryModel" width="160" label="农机型号"></el-table-column>
          <el-table-column prop="machineryType" width="120" label="农机类型"></el-table-column>
          <el-table-column prop="machineryOwnerName" width="120" label="车主姓名"></el-table-column>
          <el-table-column prop="tel" width="140" label="联系方式"></el-table-column>
          <el-table-column prop="longitude" width="120" label="经度"></el-table-column>
          <el-table-column prop="latitude" width="120" label="纬度"></el-table-column>
          <el-table-column prop="remark" width="200" label="备注"></el-table-column>
          <el-table-column prop="name" fixed="right" width="140" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="editRole(scope.row)" size="small"
                >编辑</el-button
              >
              <el-popconfirm
                icon="el-icon-info"
                icon-color="red"
                @confirm="delRole(scope.row.id)"
                title="是否确认删除此设备?"
              >
                <el-button
                  slot="reference"
                  type="text"
                  style="margin-left: 12px"
                  size="small"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: right">
        <div style="margin-top: 12px; display: inline-block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog :title="dialogTitle" width="40%" :visible.sync="roleDialog">
      <el-form
        :model="form"
        ref="cdForm"
        style="width: 80%; margin: 20px auto 0"
        :rules="rules"
        label-width="110px"
      >
        <el-form-item label="所属企业" prop="subjectionEnterprise">
          <el-input  v-model="form.subjectionEnterprise" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="农机编号" prop="agriculturalCode">
          <el-input  v-model="form.agriculturalCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="GPS设备编码" prop="gpsDeviceCode">
          <el-input  v-model="form.gpsDeviceCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="农机型号" prop="machineryModel">
          <el-input  v-model="form.machineryModel" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="农机类型" prop="machineryType">
          <el-input  v-model="form.machineryType" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="车主姓名" prop="machineryOwnerName">
          <el-input  v-model="form.machineryOwnerName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="tel">
          <el-input  v-model="form.tel" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="roleDialog = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userManufacturer: [],
      options: [{
        value: 'HK',
        label: '海康'
      }, {
        value: 'DH',
        label: '大华'
      }],
      searchForm: {
          subjectionEnterprise: '',
          gpsDeviceCode: '',
      },
      tableData: [],
      dialogFormVisible: false,
      form: {},
      roleName: "",
      dialogTitle: "新增GPS",
      roleDialog: false,
      checkedList: [],
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      rules: {
        subjectionEnterprise: {
          required: true,
          message: "请输入所属企业",
          trigger: "blur",
        },
        agriculturalCode: {
          required: true,
          message: "请输入农机编号",
          trigger: "blur",
        },
        gpsDeviceCode: {
          required: true,
          message: "请输入GPS编码",
          trigger: "blur",
        },
        machineryModel: {
          required: true,
          message: "请输入农机型号",
          trigger: "blur",
        },
        machineryType: {
          required: true,
          message: "请输入农机类型",
          trigger: "blur",
        },
        machineryOwnerName: {
          required: true,
          message: "请输入车主姓名",
          trigger: "blur",
        },
        tel: {
          required: true,
          message: "请输入联系方式",
          trigger: "blur",
        },
        
      },
      selectedList: [],
      data: [],
      defaultProps: {
        children: "children",
        label: "moduleName",
      },
    };
  },
  mounted() {
    this.search();
    if (window.innerHeight) {
      var winHeight = window.innerHeight;
    } else if (document.body && document.body.clientHeight) {
      var winHeight = document.body.clientHeight;
    }
    document.getElementById("roleList").style.minHeight = winHeight - 90 + "px";
  },
  methods: {
    search() {
      let params = {
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
        ...this.searchForm
      };
      this.qa.gpsQueryForPageList(params).then((res) => {
        if (res.respCode == "0000") {
          this.tableData = res.data;
          this.pageInfo.total = res.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getKeys(data, check) {
      // console.log(this.checkedList)
      // console.log(check)
    },

    addRole() {
      this.dialogTitle = "新增GPS";
      this.form = {
        subjectionEnterprise: '',
        agriculturalCode: '',
        gpsDeviceCode: '',

        remark: '',
      };
      this.roleDialog = true;
    },
    editRole(row) {
      this.dialogTitle = "编辑GPS";
      this.form = {
        id: row.id,
        subjectionEnterprise: row.subjectionEnterprise,
        agriculturalCode: row.agriculturalCode,
        gpsDeviceCode: row.gpsDeviceCode,
        machineryModel: row.machineryModel,
        machineryType: row.machineryType,
        machineryOwnerName: row.machineryOwnerName,
        tel: row.tel,
        remark: row.remark,
      };
      this.roleDialog = true;
    },
    details(row) {
        this.dialogTitle = "详情";
        this.form = {
            id: row.id,
            subjectionEnterprise: row.subjectionEnterprise,
            deviceUrl: row.deviceUrl,
            installArea: row.installArea,
            deviceFactory: row.deviceFactory,
            useEnterprise: row.useEnterprise,
            warehousingType: row.warehousingType,
            storeCropsType: row.storeCropsType,
            remark: row.remark,
        };
        if(row.datascreenDeviceEnterpriseRelationPOS.length != null && row.datascreenDeviceEnterpriseRelationPOS.length> 0) {
          var arr = [];
          for(var i=0; i<row.datascreenDeviceEnterpriseRelationPOS.length;i++) {
            arr.push(row.datascreenDeviceEnterpriseRelationPOS[i].useEnterprise)
          }
          this.userManufacturer = [... new Set(arr)]
        }
        this.roleDialog = true;
    },
    delRole(id) {
      this.qa.gpsDoDeleteById({id: id}).then((res) => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.search();
      });
    },
    add() {
      this.$refs.cdForm.validate((valid) => {
        if (valid) {
          if (this.dialogTitle == "新增GPS") {
            this.qa.gpsDoInsert(this.form).then((res) => {
              if (res.respCode == "0000") {
                this.$message({
                  message: "新增GPS成功！",
                  type: "success",
                });
                this.roleDialog = false;
                this.search();
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.qa.gpsDoUpdate(this.form).then((res) => {
              if (res.respCode == "0000") {
                this.$message({
                  message: "编辑GPS成功！",
                  type: "success",
                });
                this.roleDialog = false;
                this.search();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },

    handleSizeChange(page) {
      this.pageInfo.pageSize = page;
      this.search();
    },
    handleCurrentChange(page) {
      this.pageInfo.pageNo = page;
      this.search();
    },
  },
};
</script>

<style scoped>
/* .role_index {
      width: 100%;
      padding: 20px 24px;
  } */
.role_content {
  background: #fff;
}
.role_content > h4 {
  padding: 24px 24px 10px;
  font-size: 16px;
  color: #1d1e1f;
  font-weight: 400;
  line-height: 22px;
  border-bottom: 1px solid #efefef;
}
.role_button {
  padding: 10px 24px;
}
</style>